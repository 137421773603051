<template>
    <div class="partial attestation">
        <transition-group
            name="fade"
            mode="out-in"
            tag="section"
            @before-leave="onScrollTop"
        >
<!--            <study
                v-if="stage.study"
                key="stage-study"
            />-->
            <start
                v-if="stage.start"
                key="stage-start"
                @onStart="startTesting"
            />
            <question
                v-if="stage.question"
                key="stage-question"
                :question="question"
                :on-complete="onTestingCompleted"
                :on-submit="submitTesting"
            />
        </transition-group>
    </div>
</template>

<script>
import session from '@/api/session'

export default {
    name: 'Test',
    components: {
        Start : () => import('@/views/courses/test/Start'),
        Question : () => import('@/views/courses/test/Question')
    },
    data () {
        return {
            stage: {
                start: true
            },
            answers: {},
            isLoading: true,
            testId: this.$route.params.test_id,
            question: null
        }
    },
    methods: {
        onScrollTop () {
            window.scrollTo(0, 0)
        },
        onTestingCompleted (data) {
            console.log(data)
            if (data.is_passed) {
                console.log('тест пройден!')
            }
        },
        async startTesting () {
            const request = await session.get(`/api/v1/course-test/${this.testId}/start/`);
            this.question = request.data;
            this.stage.start = false
            this.stage.question = true

        },
        async submitTesting (answersList) {
            try {
                this.stage.question = false
                this.isLoading = true
                const request = await session.post('/api/v1/testing/answer_question/', answersList)
                let { data } = request
                if (data.is_wait) {
                    this.stage.wait = true
                } else if (data.is_passed) {
                    this.stage.end = true
                } else {
                    this.stage.repeat = true
                }
                this.isLoading = false
            } catch (e) {
                console.error(e)
            }
        },
        /*async loadTemplate () {
            const request = await session.get('/api/v1/testing/load_template/')
            this.stage = request.data
            this.isLoading = false
        }*/
    },
    created () {
        // this.loadTemplate()
    }
}
</script>

<style lang="scss">
@import "#sass/testing/testing";
</style>
